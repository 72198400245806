<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M11.802 18.583l.023-.02.008-.007.007-.007a3.928 3.928 0 0 0 1.181-2.483.75.75 0 0 0-1.494-.132c-.051.58-.3 1.116-.7 1.509l-.002.003-.005.004a.051.051 0 0 0 .002-.001c-.974.867-2.397.747-3.215-.26a.75.75 0 0 0-1.081-.088l-.042.036a.75.75 0 0 0 .16 1.226 3.685 3.685 0 0 0 5.158.22zM6.542.956l.02-.008.03-.015c1.52-.688 3.145-.98 4.737-.827.568.053 1.158.151 1.767.298 1.214.296 2.164.707 2.88 1.202.972.674 1.526 1.825 1.382 2.987-.14 1.143-.848 2.005-2.167 2.275-.766.158-.874.18-1.249.25-2.648.495-4.933.624-7.197.193a11.64 11.64 0 0 1-2.95-.967c-.921-.45-1.642-.97-2.176-1.55C.507 3.586.289 2.264.605 1.088.655.906.707.766.75.676A.75.75 0 0 1 1.827.359l.92.578a4.556 4.556 0 0 0 3.795.02zM1.981 2.227c.038.502.259 1.027.741 1.551.402.437.971.847 1.731 1.218.795.39 1.654.667 2.572.841 2.045.39 4.16.27 6.641-.194.363-.068.467-.089 1.223-.244.652-.134.914-.453.98-.99.073-.592-.222-1.205-.747-1.57-.559-.386-1.339-.724-2.38-.977a10.959 10.959 0 0 0-1.554-.263c-1.326-.126-2.69.118-3.955.69l-.048.023-.018.008a6.061 6.061 0 0 1-5.101-.046l-.078-.042-.007-.005z"
    />
    <path
      fill-rule="nonzero"
      d="M2.428 10.149C1.592 10.457 1 11.282 1 12.242c0 .94.497 1.744 1.268 2.069l-.314 7.153a.75.75 0 1 0 1.499.066l.341-7.783a.75.75 0 0 0-.749-.783c-.312 0-.545-.274-.545-.722 0-.407.301-.723.655-.723a.75.75 0 0 0 .75-.721l.184-4.922a.75.75 0 1 0-1.499-.057l-.162 4.33zM16.822 14.31c.771-.324 1.268-1.128 1.268-2.068 0-.96-.592-1.785-1.428-2.093l-.163-4.33a.75.75 0 1 0-1.498.057l.184 4.922a.75.75 0 0 0 .75.721c.353 0 .655.316.655.723 0 .448-.234.722-.545.722a.75.75 0 0 0-.749.783l.341 7.783a.75.75 0 1 0 1.499-.066l-.314-7.153z"
    />
    <path
      d="M10.75 11.471c0-.715.553-.99 1.236-.99.684 0 1.433.26 1.433.975s-.72 1.177-1.402 1.177c-.683 0-1.267-.446-1.267-1.162M5.75 11.471c0-.715.554-.99 1.236-.99.684 0 1.433.26 1.433.975s-.719 1.177-1.401 1.177c-.683 0-1.268-.446-1.268-1.162"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
